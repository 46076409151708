<script setup lang="ts">
import SVGDown from '@mop/shared/images/arrow/down.svg?component';

defineOptions({
  name: 'MopSuperBanner',
});

const { $storyblokLivePreview } = useNuxtApp();
const { openSuperBannerOverlay, closeSuperBannerOverlay, isOpenRef, cmsSuperBannerStoryModelRef } =
  useMopSuperBannerClient();
$storyblokLivePreview.initStoryListener('SuperBanner', cmsSuperBannerStoryModelRef);

const superBannerState = computed(() => {
  const data = getCmsSuperBanner(cmsSuperBannerStoryModelRef.value);
  if (!data) {
    return;
  }

  const hasToggle = data.hasOverlayContent;
  if (hasToggle) {
    data.link = {
      componentName: 'div',
      isLink: false,
    };
  }

  return {
    ...data,
    uiStyle: {
      '--color': data.textColor,
      backgroundColor: data.backgroundColor,
      borderColor: data.borderColor,
    },
    hasToggle,
    autoOpen: data.autoOpen,
    data,
  };
});

async function toggle() {
  if (isOpenRef.value) {
    await closeSuperBannerOverlay();
  } else if (superBannerState.value?.hasToggle) {
    await openSuperBannerOverlay();
  }
}
</script>

<template>
  <div class="superbanner-wrapper">
    <div
      v-if="superBannerState"
      :style="superBannerState.uiStyle"
      :class="['superbanner', 'lock-scroll-padding', { 'superbanner--opened': isOpenRef }]"
    >
      <UiNuxtLink
        v-bind="superBannerState.link"
        :class="[
          'superbanner__teaser',
          {
            'superbanner__teaser--toggle': superBannerState.hasToggle,
            'superbanner__teaser--uppercase': superBannerState.isUppercase,
          },
        ]"
        @click="toggle"
      >
        <div v-storyblok-editable="superBannerState.data" class="superbanner__teaser-headline">
          <div v-if="superBannerState.textLeft" class="superbanner__teaser-text superbanner__teaser-text--left">
            {{ superBannerState.textLeft }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
          <div v-if="superBannerState.textCenter" class="superbanner__teaser-text superbanner__teaser-text--center">
            {{ superBannerState.textCenter }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
          <div v-if="superBannerState.textRight" class="superbanner__teaser-text superbanner__teaser-text--right">
            {{ superBannerState.textRight }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
        </div>
        <div class="superbanner__teaser-headline superbanner__teaser-headline--mobile">
          <div v-if="superBannerState.textLeftMobile" class="superbanner__teaser-text superbanner__teaser-text--left">
            {{ superBannerState.textLeftMobile }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
          <div
            v-if="superBannerState.textCenterMobile"
            class="superbanner__teaser-text superbanner__teaser-text--center"
          >
            {{ superBannerState.textCenterMobile }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
          <div v-if="superBannerState.textRightMobile" class="superbanner__teaser-text superbanner__teaser-text--right">
            {{ superBannerState.textRightMobile }}
            <SVGDown v-if="superBannerState.hasToggle" class="superbanner__teaser-text-arrow" width="14" height="14" />
          </div>
        </div>
      </UiNuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.superbanner-wrapper {
  @include z(layers, superbanner);

  height: $superbanner-height;
  position: relative;

  @include apply-upto(small) {
    height: $superbanner-height-mobile;
  }
}

.superbanner {
  --color: #{$white};

  display: flex;
  justify-content: center;
  width: 100%;
  height: $superbanner-height;
  transition: transform 0.25s ease;
  border-bottom: 1px solid;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  border-color: $white;
  color: var(--color);
  background: $brand;

  @include apply-upto(small) {
    height: $superbanner-height-mobile;
  }
}

.superbanner__teaser {
  width: 100%;
  padding: 0 $global-padding;
  background-color: inherit;
  cursor: pointer;

  svg path {
    fill: currentColor;
  }
}

.superbanner__teaser-headline {
  @include text-style(subtopic-headline);

  position: relative;
  margin: 0;
  padding: 0;
  height: $superbanner-height;
  line-height: $superbanner-height;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  @include apply-upto(small) {
    display: none;
  }
}

.superbanner__teaser-text {
  position: absolute;
}

.superbanner__teaser-text-arrow {
  position: relative;
  top: 2px;
  transition: transform ease 0.3s;
}

.superbanner__teaser--uppercase {
  .superbanner__teaser-headline {
    text-transform: uppercase;
  }
}

.superbanner__teaser-text--left {
  left: 0;
}

.superbanner__teaser-text--center {
  left: 50%;
  transform: translateX(-50%);
}

.superbanner__teaser-text--right {
  right: 0;
}

.superbanner__teaser-headline--mobile {
  display: none;

  @include apply-upto(small) {
    display: flex;
    height: $superbanner-height-mobile;
    line-height: $superbanner-height-mobile;
  }
}

.superbanner__teaser-subheadline {
  @include text-style(running);

  display: inline-block;
  margin: 0;
  padding: 0 0 0 $space20;
  line-height: $superbanner-height;

  @include apply-upto(small) {
    padding: 0 0 0 $space10;
  }
}

.superbanner--opened {
  @include z(layers, superbanner);

  position: fixed;

  .superbanner__teaser-text-arrow {
    transform: rotate(-180deg);
  }
}
</style>
