<script setup lang="ts">
defineOptions({
  name: 'MopStoryblokLinkWidget',
});
const { $storyblokLivePreview } = useNuxtApp();

const config = useRuntimeConfig();

const storyLinkRef = computed(
  () =>
    `https://app.storyblok.com/#/me/spaces/${config.public.STORYBLOK_SPACE_ID}/stories/0/0/${$storyblokLivePreview.currentStoryModelIdRef.value}`,
);
</script>
<template>
  <div v-if="$storyblokLivePreview.currentStoryModelIdRef.value" class="storyblock-link-widget-wrapper">
    <NuxtLink :to="storyLinkRef" class="storyblock-link-widget__button" no-prefetch target="_blank"> </NuxtLink>
  </div>
</template>
<style lang="scss" scoped>
.storyblock-link-widget-wrapper {
  position: fixed;
  bottom: $space20;
  left: $space20;
  @include z(global, tooltip);

  @include apply-upto(small) {
    display: none;
  }
}

.storyblock-link-widget__button {
  width: 20px;
  height: 20px;
  padding: 15px 20px;
  box-sizing: border-box;
  background: #00b3b0;
  border-radius: 10px;
  border: none;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '\2192';
    color: $white;
    font-size: 18px;
  }
}
</style>
