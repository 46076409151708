<script setup lang="ts">
import SVGMopLogo from '@mop/shared/images/logo/logo_mop.svg?component';

defineOptions({
  name: 'MopMaintenance',
});
const { $maintenance } = useNuxtApp();
</script>

<template>
  <div class="maintenance">
    <div class="maintenance__logo">
      <SVGMopLogo class="maintenance__logo-icon" />
    </div>
    <MopCmsContentElementsSlot
      id="maitenance"
      class="maintenance__slot"
      :elements="$maintenance.story.getContentElements()"
      no-padding
    />
  </div>
</template>

<style scoped lang="scss">
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: $global-padding;
}

.maintenance__logo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: $space30;
}

.maintenance__slot {
  width: 700px;
  max-width: 100%;
}

.maintenance__logo-icon {
  display: block;
  width: 190px;
  height: 40px;

  @include apply-upto(medium) {
    width: 119px;
    height: 25px;
  }
}
</style>
